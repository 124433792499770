export const environment = {
  production: true,
  url: 'https://guatemalarentacar-api-7573f145f8c3.herokuapp.com/api/',
  ulrFront: 'https://www.guatemalarentacar.com',
  hubspotApiKey: '6a7e3ab1-4905-427a-affd-fab47e2297ef',
  placeholdervehiculo: 'https://21623072.fs1.hubspotusercontent-na1.net/hubfs/21623072/Rentacar%20Assets/Placeholders/Vehicle.webp',
    placeholdercategoria: 'https://21623072.fs1.hubspotusercontent-na1.net/hubfs/21623072/Rentacar%20Assets/Placeholders/Categorie.webp',
    placeholderoficina: 'https://21623072.fs1.hubspotusercontent-na1.net/hubfs/21623072/Rentacar%20Assets/Placeholders/Oficina.webp',
    urlassetshubspot: 'https://21623072.fs1.hubspotusercontent-na1.net/hubfs/21623072/Rentacar%20Assets'

};
