import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { HomeComponent } from './components/renta-car/home/home.component';


const routes: Routes = [
  /*{
    path: 'home', 
    component: HomeComponent,
  },
  
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegistroComponent
  },
  {
    path: 'my-profile',
    component: PerfilComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'search',
    component: BusquedaComponent
  },
  {
    path: 'details-rent/:name/:search/:id',
    component: DetalleRentaComponent
  },
  {
    path: 'categories/:id',
    component: CategoriasComponent
  },
  {
    path: 'sale-detail/:id',
    component: SaleDetailComponent
  },
  
  {
    path: 'login/:id',
    component: LoginComponent
  },
  { 
    path:'sale-detail', 
    redirectTo:'/search', 
    pathMatch:'full'
  },
  {
    path:'reservations', 
    component: ReservationsComponent ,
    canActivate: [AuthGuard] 
  },
  {
    path: 'helpcenter',
    component: HelpcenterComponent
  },
  {
    path: 'contactus',
    component: ContactusComponent
  },
  {
    path: 'rentalpolicy',
    component: RentalPolicyComponent
  },
  {
    path: 'terminos-y-condiciones',
    component: TerminosYCondicionesComponent
  },
  {
    path: 'our-fleet/:params',
    component: OurFleetsComponent
  },
  {
    path: 'select-car/:id',
    component: SelectCarComponent,
    canActivate: [AuthGuard], 
    canDeactivate: [CanDeactiveGuard]
  },
  {
    path: 'sales',
    component: SalesComponent
  },
  {
    path: 'payment/:tokenpay',
    component: PaymentComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PaymentGuard]  
  },
  {
    path: 'selection-rates',
    component: SimulateReserveComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard] 
  },
  {
    path: 'payment-completed',
    component: PaymentCompletedComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'forgotpassword',
    component: ForgotpasswordComponent
  },
  {
    path: 'requestpassword/:token',
    component: RequestpasswordComponent
  },
  {
    path: 'locations',
    component: LocationsComponent
  },
  {
    path: 'modifyreserve',
    component: ModifyReserveComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: '**', 
    redirectTo:'/home'
  },*/
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./components/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./components/renta-car/renta-car.module').then(
        (m) => m.RentaCarModule
      ),
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

/*@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})*/

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  initialNavigation: 'enabledBlocking'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
